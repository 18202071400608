.homeContainer {
  height: inherit;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: #f4f4f4;
  display: flex;
  background: #2a1744;
  flex-direction: column;
  margin: 0px auto;
  .containerTitle {
    display: flex;
    align-items: center;
    .title {
      font-size: 44px;
      font-weight: bold;
    }
  }

  .button-section {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 10px;
  }

  .camera-section {
    position: relative;
    margin: 30px;
    width: 33%;
    overflow: hidden;
  }

  video,
  canvas {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cancel-button {
    background: transparent;
    border: solid 1px #dadada;
    color: white;
    width: 150px;
    height: 45px;
    text-align: center;
    font-size: 16px;
    border-radius: 35px;
    margin: 0px 20px 10px 10px;
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .input-container {
    position: relative;
    display: inline-block;
  }

  .input-icon {
    position: absolute;
    left: 10px;
    top: 20px;
    transform: translateY(-50%);
    color: white;
  }

  .linput {
    padding-left: 30px !important;
  }

  .textoCamra {
    position: absolute;
    top: 0px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    background: black;
    margin: 0px;
    padding: 4px 0px;
  }

  .prelimchose {
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .buttonConfiguracion {
    background: transparent;
    border: none;
    font-size: 23px;
  }

  .labelPop {
    font-size: 15px;
    color: white;
  }

  .btnCamara {
    display: inline;
    width: 220px;
    margin-top: 23px;
    background: linear-gradient(to right, #f600c0 0%, #4409a4 100%);
    border: none;
    border-radius: 4px;
    color: white;
    height: 45px;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    border-radius: 35px;
    margin-bottom: 10px;
  }
}

.RegisterAcess {
  display: flex;
  flex-direction: column;
  position: relative;
  h3 {
    width: 100%;
    border-bottom: 3px solid #4409a4;
    padding-left: 40px;
  }
  .tabletcameracontainer {
    width: 100%;
    justify-content: space-around;
    overflow: hidden;
    transition: max-height 0.5s linear;
    margin-top: -40px;
    transition: 'max-height 0.5s linear 0s';
  }
}

.personalStatus {
  display: flex;
}
