.AccreditationsContainer {
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  padding: 20px;
  margin: 0rem 0rem 5rem 0rem;
}

.title_Accreditations h4 {
  margin-bottom: 0px;
  font-size: 40px;
}

.accreditations-search-section {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 1rem 1rem 0rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
  padding-bottom: 10px;
  svg {
    color: white;
  }

  .dashboard-search-bar-container-dashboard {
    display: flex;
    align-items: center;
    background: rgb(80, 74, 87) !important;
    width: 30%;
    height: 30px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
  }
}

.accreditations-search-bar-container {
  display: flex;
  align-items: center;
  background: rgba(80, 74, 87, 1) !important;
  width: 180px;
  height: 20px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;

  .accreditations-search-icon {
    margin-right: 8px;
  }

  .accreditations-search-input {
    color: white;
    background-color: transparent;
    border: none;
    outline: none; // Quitar el borde de color azul al hacer clic
    width: 170px; // Ocupar todo el ancho disponible

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    .accreditations-search-input {
      border: none;
    }
  }
}

///////////

.accreditations-container {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 20px;

  .header {
    border: 1px solid #261a3a;
    border-radius: 11px;
    background-color: #14113f;
  }

  .order {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-items: center;
    align-content: center;
  }

  .Payments {
    border: 1px solid #14113f;
    border-radius: 5px;
    background-color: #261a3a;
    justify-content: space-between;
    .DestineAndStatus {
      display: flex;
      gap: 4px;
      width: 100%;
      height: 100%;
      justify-content: center;
      position: relative;
      align-items: center;

      svg {
        border-radius: 100%;
        left: 0rem;
        position: absolute;
      }
    }
  }

  span {
    font-size: 14px;
    text-align: center;
  }
}

.tooltip .tooltip-content {
  visibility: hidden;
  display: inline-block;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}

.Container_kpis {
  display: flex;
  position: relative;
  align-items: center;
  margin: 1rem;
  justify-content: space-around;
  margin: 1rem 2rem;
  padding: 1rem 0rem 1rem;
  border-color: #5a1fd1;
  border-bottom-style: inset;
  .generalInf {
    list-style: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    div {
      display: flex;
      gap: 5px;
    }
  }
  svg {
    font-size: 2.1rem;
    margin: 0 5px;
  }
  span {
    margin: 0;
    height: 100%;
    display: inherit;
    align-items: center;
    font-weight: bold;
  }
}

.ContainerCharters {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
