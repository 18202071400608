.clientsContainer {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: 'flex';
  width: '100%';
  height: '100%';
  overflow: 'hidden';
  background: 'rgb(32, 26, 41)';
  .title {
    margin-bottom: 20px;
    p {
      font-size: 44px;
      font-weight: bold;
    }
  }
  .buttonAction {
    display: flex;
    flex-direction: row;
  }

  .Container_Mora_client {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;

    .Text {
      font-weight: bold;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &.active {
        color: red;
      }
    }
  }
}
