/* styles.css */

.containerDragAndDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.ContaineheadingDragAndDrop {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headingDragAndDrop {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
    margin-left: 15px;
  }
}

.itemDragAndDrop {
  background-color: #2a1744;
  display: flex;
  width: 95%;
  min-height: 50px;
  max-height: 50px;
  color: aliceblue;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 0px;
    width: 60%;
    p {
      width: 100%;
    }
  }

  .AddressAndQr {
    width: 40%;
    margin: 0 2rem;
    display: flex;
    align-items: center;

    p {
      width: 100%;
    }

    svg {
      cursor: pointer;
    }
  }
}
.containerColumnDragAndDrop {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0rem;
}
.columnDragAndDrop {
  display: flex;
  background-color: rgba(56, 78, 183, 0.3);
  border-radius: 20px;
  height: 60vh;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;

  overflow: scroll; /* Mantiene el desplazamiento funcional */
  scrollbar-width: none; /* Oculta el scrollbar en navegadores Firefox */
  -ms-overflow-style: none; /* Oculta el scrollbar en Internet Explorer/Edge */
}

.emptyDropZone {
  margin: auto;
  text-align: center;
  color: #888;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  height: 90%;
  width: 90%;
}

.formElement {
  width: calc(100% - 10px);
  margin-bottom: 20px;
}
