.multi-select-container2 {
  margin-bottom: 5px;
  margin-right: 10px;
  border-radius: 11px;
  width: 145px;

  div {
    font-size: 14px;
  }
  .MuiFormControl-root css-c5bdi2-MuiFormControl-root {
    width: 160px;
  }
}

.multi-input-label2 {
  color: white;
  margin-bottom: 5px;
}

.multi-select-element2 {
  cursor: pointer;
  border-radius: 11px !important;
  height: 40px;
  background-color: #14113f;
  color: white;
  display: flex;
  align-items: center;
}

.multi-menu-item2 {
  background-color: #14113f;
  color: white;
}

.multi-menu-item2:hover {
  background-color: #1a174b;
}

.multi-checkbox2 {
  color: white;
}
