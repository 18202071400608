.input-section {
  //   flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .containerInputs {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    span {
      font-weight: 300;
    }
  }
}
