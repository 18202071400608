body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-bottom: 0px !important;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.3;
}

h3 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.4;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;
}

h5 {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.6;
}

h6 {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.7;
}

p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

/* Para navegadores basados en WebKit (como Chrome y Safari) */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #261a3a;
}

body {
  scrollbar-color: #5a1fd1 #261a3a;
  scrollbar-width: 2px;
}

.title {
  color: white;
  margin-bottom: 10px;
}
