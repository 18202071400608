.StatementResident {
  width: 100%;
  padding: 0px;
  .title {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 2px solid #5a1fd1;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 10px;

    .headerAcountState {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .acountState {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;
        h4 {
          width: 100%;
          text-align: start;
          font-weight: 700;
          font-size: 16px;
          color: white;
          margin: 0;
        }
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          text-align: start;
          width: 100%;
          margin-left: 2rem;
        }
      }
    }

    .svg {
      position: absolute;
      left: -10px;
      top: 15px;
      svg {
        background-color: #2a1744;
      }
    }

    .svgHeader {
      position: absolute;
      left: -10px;
      top: 65px;
      svg {
        background-color: #2a1744;
      }
    }

    .svgPdf {
      position: absolute;
      right: 0px;
      top: 40px;
    }

    p {
      width: 100%;
      text-align: start;
      font-weight: 700;
      font-size: 16px;
      color: white;
    }

    .svgPlus {
      .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
        padding: 0px !important;
      }
    }
  }

  .containerSlider {
    margin: 15px 10px;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    transition: max-height 0.5s linear 0s;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
  }

  .containerMap {
    margin: 5px 0px;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    gap: 1rem;
    .details {
      width: 100%;
      display: grid;
      align-items: baseline;
      align-content: center;
      justify-content: center;
      justify-items: start;
    }
  }

  .totalPrice {
    border-top: 2px solid #5a1fd1;
    margin: 0px 0px 25px 0px;
    padding: 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
  }

  .PriceContainer {
    width: 100px;
    text-align: end;
    margin: auto 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    button {
      padding: 0px;
    }
  }
}

.containerSetCuota {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  gap: 2rem;

  span {
    font-weight: 400 !important;
  }

  .NameCuota {
    height: 40px;
  }
  .title {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: 2px solid #5a1fd1;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: nowrap;

    p {
      width: 100%;
      text-align: start;
      font-weight: 700;
      font-size: 16px;
      color: white;
    }
  }

  .ButtonCuota {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.deleteConceptContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  .sub-title {
    font-size: 22px;
  }
  p {
    width: 100% !important;
    font-weight: 700;
  }
  .border {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 5px;
    position: relative;
    border-radius: 20px;
  }
}
.buttonContainer {
  width: 45%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    overflow: hidden;
    position: absolute;
    display: flex;
    height: 34px;
    width: 170px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disable {
      cursor: default;
    }
    div {
      display: flex;
      height: -webkit-fill-available;
      align-items: center;
      margin: 3px;
      font-size: larger;
    }
  }
}

.miniDeleteLoading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    width: 90% !important;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(20px);
  }

  .loading-circle {
    border: 4px solid transparent;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.miniWarnig {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  p {
    width: 95% !important;
    font-size: 0.9rem !important;
  }
  .Bottom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    .inner {
      padding: 6px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
