.borderAnimateIpass .inner,
.borderAnimateIpassError .inner,
.borderAnimateIpassDisable .inner {
  position: relative;
  z-index: 1;
  height: -webkit-fill-available;
  width: 100%;
  margin: 1px;
  display: flex;
  justify-content: space-evenly;
  background: #2a1744;
  border-radius: 20px;
}

.borderAnimateIpass::before,
.borderAnimateIpassError::before,
.borderAnimateIpassDisable::before {
  content: '';
  display: block;
  position: absolute;

  transform: translate(-50%, -50%);
  width: calc(max(150%, 100vh) + 20px); /* Toma el lado más largo + margen extra */
  height: calc(max(150%, 100vw) + 20px); /* Igual que arriba */
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  animation: rotate 1s linear infinite;
  z-index: 0;
}

.borderAnimateIpass::before {
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
}

.borderAnimateIpassError::before {
  background: linear-gradient(90deg, #ff0000 0%, #b700ff 100%);
}

.borderAnimateIpassDisable::before {
  background: linear-gradient(90deg, #bbbbbb 0%, #ffffff 100%);
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.border {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  border-radius: 20px;
}
