// style.scss

.modal-overlay {
  scrollbar-color: #5a1fd1 #261a3a;
  scrollbar-width: thin;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 20;
  justify-content: center;

  .modal {
    width: 90%; // Ajustamos el ancho del modal al 90% del contenedor padre
    max-width: 1100px; // Establecemos un ancho máximo para evitar que sea demasiado grande
    height: 100%;
    max-height: 85vh; // Establecemos una altura máxima para evitar que se desborde por abajo
    overflow-y: auto; // Añadimos una barra de desplazamiento vertical si es necesario
    display: flex;
    flex-direction: column;
    background: #2a1744;
    border-radius: 53px;
    position: relative;

    .content {
      padding: 20px 60px;
      height: 100%;
      max-height: 85vh;
      display: flex;
      overflow: hidden;
      .subContent {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
      }

      &.borderModal {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: 5px;
        position: relative;
        border-radius: 20px;
      }
      &.borderModal::before {
        width: 200vw;
        height: 200vh;
      }
    }

    .close-button {
      position: absolute;
      font-size: 28px;
      background: none;
      border: none;
      cursor: pointer;
      color: white;
      font-weight: bold;
      right: 25px;
      top: 25px;
      z-index: 99;
    }
  }
}

.scrollable-container::-webkit-scrollbar {
  width: 2px !important;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #ff0000 !important;
  border-radius: 20px !important;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #261a3a !important;
}
