/* Component.module.scss */
.gridContainer {
  display: grid;
  gap: 1.5rem;
  margin: 50px 1.5rem 40px 1.5rem;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.title {
  margin-bottom: 20px;
  h4 {
    font-size: 44px;
    font-weight: bold;
    margin-top: 25px;
    margin-left: 25px;
  }
}

.calendarMonth {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background: #ccc;
  width: -webkit-fill-available;
}

.monthHeader {
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #ffffff;
  border-radius: 0.5rem;
  background-color: #ca2525;
}
.monthHeaderSelect {
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #ffffff;
  border-radius: 0.5rem;
  background-color: #25ca25;
}
.daysGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.DaysCalendar {
  font-weight: bold;
  color: #000000;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}
.DayCalendar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border-color: transparent;
}

.containerButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .custom-button {
    margin: 0;
  }
  .SelectValue {
    display: flex;
    color: white;
    font-weight: bold;
    gap: 1rem;
  }
}

.secondContainerButtons {
  display: none;
  justify-content: center;
  margin-bottom: 2rem;
}

.dayButton {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: rgb(200, 200, 200);
    color: var(--primary-foreground);
  }

  &.selected {
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    &.mix {
      background-color: #ffe600;
    }
    &.limit {
      background-color: #ff0000;
    }
    &.Court {
      background-color: #ff65c3;
    }
  }
}

@media (max-width: 1540px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }

  .secondContainerButtons {
    display: flex;
  }
}
