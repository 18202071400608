.paymentsDetail-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  .containerphoto {
    margin: 0 1rem;
    width: 35%;
    .photo {
      width: 35%;
      height: 85%;
      position: absolute;
      top: 7%;
      img {
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }

  .inf {
    height: 95%;
    width: 65%;
    padding: 0 1rem;

    .database-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      border-color: #5a1fd1;
      border-bottom-style: inset;

      .data {
        text-align-last: left;
        p {
          font-size: 1.2rem;
          width: 100%;
          line-height: 1.7rem;
        }
        p:last-child {
          font-size: 1rem;
          padding-top: 7px;
        }
      }
      .status {
        width: 25%;
        margin-top: auto;

        svg {
          font-size: 4rem;
          animation: none;
        }
      }

      .svgPdf {
        margin-top: auto;
      }
    }

    .statement {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      margin: 1.2rem 0;
      gap: 0.5rem;
      max-height: 60%;
      min-height: 130px;
      overflow: overlay;

      .detail {
        display: grid;
        width: 100%;
        align-items: center;
        justify-items: center;
        text-align: center;
        align-content: center;
        position: relative;
      }
    }
    .totalStatement {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 7rem;
      border-color: #5a1fd1;
      border-top-style: outset;
      padding-top: 1rem;
      span {
        margin-right: 3.5rem;
      }
    }

    .actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      bottom: 4rem;
      margin-top: 5rem;
      .custom-button {
        padding: 10px 7px;
      }
    }
  }
}

.InfoModal {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 1rem;
  .header {
    width: 100%;
    p {
      margin: 0 auto;
      width: auto;
    }
    .Amounts {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      p {
        margin: 0;
        width: auto;
      }
      .custom-number-input {
        position: relative;
        width: 100%;

        input {
          appearance: none;
          -moz-appearance: 'textfield';
          -webkit-appearance: none;
          width: 70%;
          background-color: transparent;
          border: none;
          outline: none;
          text-align: end;
          color: white;
        }

        .controls {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .controls button {
          background: none;
          border: none;
          font-size: 10px;
          cursor: pointer;
          color: #ffffff;
          line-height: 1;
          width: 30px;
          height: 50%;
        }

        .controls button:hover {
          color: #c567fc;
        }
      }
    }
  }
  .buttom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .inner {
      cursor: pointer;
      height: 40px;
      width: 130px;
      text-align: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.modalReject {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 1rem;
  .myTexarea {
    width: 95%;
    p {
      margin: 0 !important;
    }
  }
  p {
    width: 100%;
    margin: 0;
  }
  .buttom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .inner {
      cursor: pointer;
      height: 40px;
      width: 130px;
      text-align: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
