.whatsAppContainer {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(32, 26, 41);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2rem;

  .title {
    margin-bottom: 20px;
    position: absolute;
    left: 2rem;
    top: 2rem;
    p {
      font-size: 44px;
      font-weight: bold;
    }
  }
}
