.containerCheckInDetail {
  .images-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .single-image {
      align-items: center;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      height: 105px;
      justify-content: center;
      width: calc(25% - 10px);
    }

    .image {
      height: 100%;
      object-fit: contain;
      margin: 0;
    }
    .circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        font-weight: bold;
      }
    }
    .green {
      background-color: rgb(0, 248, 0);
    }
    .yellow {
      background-color: rgb(216, 250, 0);
      p {
        color: black;
      }
    }
    .red {
      background-color: rgb(222, 16, 16);
    }
  }

  .content-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .form-container {
      flex: 1;
      input[type='text'],
      input[type='date'],
      input[type='time'] {
        padding: 10px;
        border: 1px solid white;
        background-color: transparent;
        font-family: 'Montserrat', sans-serif;
        color: white;
        &:hover,
        &:focus,
        &:focus-visible {
          color: white;
          border: 1px solid white;
          outline-color: transparent;
        }
      }
      label {
        display: block;
        margin-bottom: 2px;
        font-size: 11px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        color: #b0b0b0;
      }

      .input-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 5px;
      }

      .input-row > div {
        display: flex;
        flex-direction: column;
        width: calc((100%) / 4);
      }
    }
    .small-images-container {
      float: right;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      .small-image {
        height: 45%;
        background-size: cover;
        background-position: center;
        display: flex;
        cursor: pointer;
        align-items: center; /* Centra el SVG verticalmente */
        justify-content: center; /* Centra el SVG horizontalmente */
      }
      .single-image-brand {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          max-width: 20vh;
          object-fit: cover;
        }
      }
    }
  }
}
