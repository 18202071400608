.newDelegateResidentForm {
  h2 {
    color: white;
    margin-bottom: 10px;
  }
}
.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.enableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 4px;
    font-size: large;
  }
  .green {
    color: rgb(8, 239, 62);
  }
  .red {
    color: rgb(239, 16, 8);
  }
}
