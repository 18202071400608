/* style.scss */
.custom-select-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiAutocomplete-root {
    &.Mui-focused {
      .MuiAutocomplete-inputRoot {
        border-color: #484747;
      }
    }

    .MuiAutocomplete-inputRoot {
      border: 1px solid #484747;
      border-radius: 20px;

      display: flex;
      align-items: center;
      background-color: #263371 !important;

      .MuiAutocomplete-input {
        color: white;
      }

      .MuiAutocomplete-popupIndicator {
        color: white;
      }
      .MuiAutocomplete-endAdornmen {
        background-color: #263371;
      }
    }

    .MuiAutocomplete-clearIndicator {
      color: white;
    }

    .MuiAutocomplete-option {
      color: white;

      &.Mui-focused {
        background-color: rgba(56, 78, 183, 0.5);
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    background-color: #263371 !important;
}


}
