* {
  margin: 0;
  box-sizing: border-box;
}

.infoGeneralContainer {
  margin-bottom: 2rem;
}

.client-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  p {
    display: inline-block;
  }
}

.client-slide {
  display: flex;
  flex-shrink: 0;
}

.client-item {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 1rem;
}

.client-item p,
img {
  font-size: 18px;
  margin: 4px;
}

.client-photo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.client-container:hover .client-slide {
  animation-play-state: paused !important;
}

/* //apartado de gráficas */

.graph-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.graph {
  width: 50%;
  margin: 1rem 0px;
}

@media (width > 1364px) {
  .graph {
    width: 33%;
  }
}
