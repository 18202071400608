.residentDetails {
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif;
  color: #f4f4f4;
  display: 'flex';
  width: '100%';
  height: '100%';
  overflow: 'hidden';
  background: 'rgb(32, 26, 41)';
  .title {
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: 44px;
      font-weight: bold;
    }
    svg {
      font-size: 3rem;
      cursor: pointer;
    }
  }
  .buttonAction {
    display: flex;
    flex-direction: row;
  }
  .containerButton {
    width: 500px;
  }
}
