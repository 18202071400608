.date-range-picker3-container {
  color: white !important;
  width: 100%;
  max-width: 200px;

  input {
    color: white !important;
  }
  path {
    color: white !important;
  }

  .date-picker3-wrapper {
    background-color: rgba(56, 78, 183, 0.3); // Fondo del contenedor
    border-radius: 11px;
    color: white !important;
    width: 100%;
    height: 40px; // Altura del contenedor
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important;

    .ant-picker-input ant-picker-input-active {
      .ant-picker .ant-picker-input > input {
        color: white !important;
      }
    }

    .ant-picker {
      background-color: rgba(56, 78, 183, 0.3); // Fondo del campo del picker
      color: white !important;

      .ant-picker-input {
        color: white !important;
        background-color: rgba(56, 78, 183, 0.3); // Fondo del campo de entrada

        // Estilo para el placeholder
        &::placeholder {
          color: white !important;
        }
      }

      .ant-picker-suffix {
        color: white !important;
      }

      .ant-picker-panel {
        background-color: rgba(56, 78, 183, 0.3); // Fondo del panel de selección
        color: white !important;
        .ant-picker-cell {
          color: white !important;
        }

        .ant-picker-cell-in-range {
          background-color: #5a1fd1; // Fondo de celdas en rango
          color: white !important;
        }

        .ant-picker-cell-selected {
          background-color: #5a1fd1; // Fondo de celdas seleccionadas
          color: white !important;
        }

        .ant-picker-cell:hover {
          background-color: #5a1fd1; // Fondo de celdas en hover
          color: white !important; // Color del texto en celdas en hover
        }
      }

      .ant-picker-clear {
        color: white !important;
      }
    }
  }

  .date-picker3-dropdown {
    .ant-picker-panel {
      background-color: rgba(56, 78, 183, 0.3); // Fondo del panel desplegable
      color: white !important;
      .ant-picker-cell {
        color: white !important;
        &:hover {
          background-color: #5a1fd1; // Fondo de celdas en hover
          color: white !important; // Color del texto en celdas en hover
        }
      }

      .ant-picker-cell-in-range {
        background-color: #5a1fd1; // Fondo de celdas en rango
        color: white !important;
      }

      .ant-picker-cell-selected {
        background-color: #5a1fd1; // Fondo de celdas seleccionadas
        color: white !important;
      }
    }
  }

  .ant-picker-range-separator {
    padding: 0 !important;
    color: white !important;
    .ant-picker-separator {
      color: white !important;
    }
  }

  // Estilo específico para el placeholder del input de rango
  input[aria-invalid='false'][placeholder] {
    &::placeholder {
      color: white !important; // Asegura que el color del placeholder sea blanco
    }
  }

  // Estilo para asegurar que los textos en los inputs sean blancos
  .ant-picker-input {
    color: white !important; // Color del texto en el input
    &::placeholder {
      color: white !important; // Asegura que el color del placeholder sea blanco
    }
  }

  .ant-picker-range .ant-picker-active-bar {
    background: white;
  }
}

.date-range-picker3-container {
  .ant-picker-outlined:focus,
  .ant-picker-outlined:hover,
  .ant-picker-outlined:focus-within {
    border-color: white !important;
    background-color: rgba(
      56,
      78,
      183,
      0.3
    ) !important; // Mantiene el fondo consistente si es necesario
    .ant-picker-outlined {
      border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important;
    }
  }
}
