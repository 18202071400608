.containerUserInfo {
  position: fixed;
  top: 5%;
  right: 5%;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease, transform 0.3s ease;
  align-items: stretch;
  z-index: 9;

  .imageContainer {
    border-radius: 50%;
    margin-right: 20px;
    width: 55px;
    height: 55px;
    display: flex;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .textContainer {
    p {
      color: white;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
}

.containerUserInfo.visible {
  opacity: 1;
  transform: translateY(0);
}

.containerUserInfo.hidden {
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
}

.expirationWarning {
  height: 65px;
  width: 500px;
  position: absolute;
  top: 5%;
  transform: translateX(0);
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  align-content: center;
  flex-direction: row;
  overflow: hidden;
  border-radius: 20px;
  z-index: 999;
  .textZone {
    margin-left: 20px;
    width: 50%;
    font-size: 12px;
    h4 {
      font-size: 17px;
      font-weight: bold;
    }
  }

  .time {
    width: 25%;
    font-size: 28px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resumeContainer {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 150px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
    margin: auto;
    overflow: hidden;
  }
}

.borderAnimateIpass .inner,
.borderAnimateIpassError .inner,
.borderAnimateIpassDisable .inner {
  position: relative;
  z-index: 1;
  height: -webkit-fill-available;
  width: 100%;
  margin: 2px;
  display: flex;
  justify-content: space-evenly;
  background: #2a1744;
  border-radius: 20px;
}

.borderAnimateIpass::before {
  content: '';
  display: block;
  background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%);
  height: 100vh;
  width: -webkit-fill-available;
  position: absolute;
  animation: rotate 1s linear infinite;
  z-index: 0;
}

.borderAnimateIpassError::before {
  content: '';
  display: block;
  background: linear-gradient(90deg, #ff0000 0%, #b700ff 100%);
  height: 100vh;
  width: -webkit-fill-available;
  position: absolute;
  animation: rotate 1s linear infinite;
  z-index: 0;
}

.borderAnimateIpassDisable::before {
  content: '';
  display: block;
  background: linear-gradient(90deg, #bbbbbb 0%, #ffffff 100%);
  height: 100vh;
  width: -webkit-fill-available;
  position: absolute;
  animation: rotate 1s linear infinite;
  z-index: 0;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.modalMora {
  display: flex;
  width: 98%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .Title {
    position: relative;
    margin-bottom: 0.7rem;
    svg {
      position: absolute;
      bottom: -35px;
      font-size: 3rem;
      width: 100%;
      left: 0;
      margin: 0;
    }
  }
  p {
    margin: 0 auto;
    width: 100% !important;
    font-size: 1.1rem !important;
    line-height: 1.2rem !important;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: bolder;
  }

  h4 {
    position: relative;
    display: inline-block;
    font-weight: bolder;
    font-size: 24px;
    color: #ffffff;
  }

  h4::before,
  h4::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 50px; /* Longitud del borde */
    height: 2px; /* Grosor del borde */
    background: linear-gradient(90deg, #e516bb 0%, #6125f0 100%); /* Gradiente de colores */
  }
  .red::after,
  .red::before {
    background: linear-gradient(90deg, #e516bb 0%, #ff0000 100%); /* Gradiente de colores */
  }

  h4::before {
    left: -60px; /* Posición del borde izquierdo */
  }

  h4::after {
    right: -60px; /* Posición del borde derecho */
  }

  .buttoom {
    cursor: pointer;
    .inner {
      height: 30px;
      width: 150px;
      text-align: center;
      align-items: center;
    }
  }
}
