.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; // Asegura un ancho fijo o relativo
  min-height: 200px;
  height: 100%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  text-align: center;

  .file-upload-content {
    width: 90%;
    .titleFile {
      size: 14px;
      width: 100%;
    }

    .file-upload-placeholder {
      color: #888;
      width: 100%;
      font-size: 14px;
      pointer-events: none; // Evita que interfiera con los eventos del arrastre
    }
  }

  .file-upload.dragging {
    background-color: #f0f8ff;
    border-color: #007bff;
  }

  .file-upload-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: #4b398a8a;
  }

  .file-name {
    flex-grow: 1;
    font-size: 14px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-button {
    background: none;
    border: none;
    color: #ff4d4f;
    font-size: 20px;
    cursor: pointer;
  }

  .delete-button:hover {
    color: #ff7875;
  }
}
