.date-range-picker-container {
  display: flex;
  background-color: rgba(56, 78, 183, 0.3);
  border: #484747 1px solid;
  border-radius: 20px;
  width: 48%;
  margin-bottom: 10px;
  cursor: pointer;
  .date-picker {
    border: none;
    background-color: transparent;
    color: white;
    width: 100%;
    margin: 10px;
    &.ant-picker-focused {
      border-color: white;
      box-shadow: none;
    }

    .ant-picker-clear {
      color: white;
    }

    .ant-picker-input {
      input {
        color: white;
        &::placeholder {
          color: #f4f4f4cf;
        }
      }
    }

    .ant-picker-suffix {
      color: white;
    }
  }

  .date-picker-dropdown {
    background-color: rgba(56, 78, 183, 0.3);

    .ant-calendar {
      background-color: transparent;
      border: none;
      color: white;

      .ant-calendar-input-wrap {
        input {
          color: white;
          &::placeholder {
            color: #f4f4f4cf;
          }
        }
      }

      .ant-calendar-range-picker-separator {
        color: white;
      }

      .ant-calendar-disabled-cell {
        color: #bfbfbf;
        pointer-events: none;
      }

      .ant-calendar-selected-day {
        background-color: #40a9ff;
        color: white;

        &.ant-calendar-disabled-day {
          background-color: transparent;
          color: #bfbfbf;
        }
      }

      .ant-calendar-selected-date-range {
        background-color: rgba(56, 78, 183, 0.5);
        color: white;
      }
    }
  }
}
